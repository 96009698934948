<template>
  <div>
    <!--  备案许可证-->
    <div class="fromDiv">
      <div class="formLine">
        <FromLabel label="备案凭证"></FromLabel>
        <div class="allWidth">
          <Input class="iviewIptWidth307" placeholder="仅支持jpg.gif.png等格式的图片">
            <span slot="append" class="finger">
              <FormUpload label="上传"></FormUpload>
            </span>
          </Input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="备案号" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="企业名称" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="法人" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="企业代表" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth307"></DatePicker>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="住所" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="生产地址" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="生产范围" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="备案部门"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="备案日期"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker type="date" placeholder="请选择" class="iviewIptWidth307"></DatePicker>
        </div>
      </div>
    </div>
    <!-- <div class="tableTitle">
      <span class="title">
        经营范围
      </span>
      <span class="longTextBtn finger btnReset fr" @click="addBill"> 新增 </span>
    </div>
    <Table :productList="listColumns" :productData="listData" :total="total" :pages="pages" @change-page="changePage" :isLoad="isLoad" totalText="条记录">
    </Table> -->
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
import FormUpload from '@/components/formUpload'
// import Table from '@/components/table'
export default {
  name: 'recordCertificateF',
  components: {
    FromLabel,
    FormUpload
    // Table
  },
  data() {
    return {
      type: '',
      license: [],
      prolong: false,
      isLoad: false,
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 68
        },
        {
          title: '2002版医疗器械分类目录',
          key: 'time',
          align: 'center'
        },
        {
          title: '2002版医疗器械分类目录',
          key: 'time',
          align: 'center'
        },
        {
          title: '细分名称',
          align: 'center',
          render: (h, param) => {
            return h('div', [h('span', '¥' + param.row.money)])
          }
        },
        {
          title: '操作',
          align: 'center',
          width: 220,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    fontSize: '14px',
                    cursor: 'pointer'
                  },
                  on: {
                    click: () => {
                      this.deleteBill(param.row)
                    }
                  }
                },
                '删除'
              )
            ])
          }
        }
      ],
      listData: [
        {
          no: '567890',
          time: '2022-08-01 20:01:02',
          money: 1000.0
        }
      ],
      total: 0,
      pages: {
        page: 1,
        rows: 10
      }
    }
  },
  created() {},
  methods: {
    changePage(e) {
      console.log(e)
    },
    addBill() {
      console.log('新增开票信息')
    },
    deleteBill() {}
  }
}
</script>

<style scoped lang="less">
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
.tableTitle {
  margin: 17px 0 20px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
</style>
